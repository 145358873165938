// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

window.Rails = Rails

import 'bootstrap'

// Incompatible with Bootstrap v .5
//import 'data-confirm-modal'

require("chosen-js")

global.Swal = require("sweetalert2")

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

// Initialize Chosen
document.addEventListener("turbolinks:load", () => {
	$(".chosen-select").chosen()
});


// Initialize FontAwesome
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)
// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()

global.intlTelInput = require("intl-tel-input")

// Include the assets

import "../stylesheets/application.scss"


// Include Images
require.context('../images', true)

global.$ = $;
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
